import request from '@/utils/axios'
// 匹配申请列表
export function matchingList (params) {
  return request({
    url: '/api/clist',
    method: 'get',
    params
  })
}
// 客户 更新 处理人
export function cupdate (params) {
    return request({
      url: '/api/cupdate',
      method: 'post',
      params
    })
}
