<!-- 顾问匹配申请列表 -->
<template>
  <div class="contract">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="username" align="center" label="姓名">
      </el-table-column>
      <el-table-column prop="sex" align="center" label="性别">
      </el-table-column>
      <el-table-column prop="age" align="center" label="年龄">
      </el-table-column>
      <el-table-column prop="grade" align="center" label="目前就读年级">
      </el-table-column>
      <el-table-column prop="school" align="center" label="学校名称">
      </el-table-column>
      <el-table-column prop="address" align="center" label="学校地址">
      </el-table-column>
      <el-table-column prop="schooltype" align="center" label="学校类型">
      </el-table-column>
      <el-table-column prop="rzsj" align="center" label="入住时间">
      </el-table-column>
      <el-table-column prop="rzsc" align="center" label="入住时长">
      </el-table-column>
      <el-table-column prop="expect" align="center" label="期望找什么样的住家" width="180">
      </el-table-column>
      <el-table-column prop="handle" align="center" label="接待人">
        <template slot-scope="scope">
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              <div :class="{ kz: scope.row.handle != '无' }" class="a">
                {{ scope.row.handle }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="commandValue('王静芳', scope.row.id, scope.$index)"
                >王静芳</el-dropdown-item
              >
              <el-dropdown-item :command="commandValue('沈馨园', scope.row.id, scope.$index)"
                >沈馨园</el-dropdown-item
              >
              <el-dropdown-item :command="commandValue('原晓婷', scope.row.id, scope.$index)"
                >原晓婷</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="fy">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { matchingList,cupdate } from "@/api/matchinglist.js";
export default {
  data() {
    return {
      tableData: [],
      page: 0,
      limit: 10,
      total: 0, //总数
    };
  },
  methods: {
    commandValue(type, id, key) {
      return {
        type: type,
        id: id,
        key: key,
      };
    },
    handleCommand(e) {
      console.log(e);
      cupdate({
        handle: e.type,
        id: e.id,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.tableData[e.key].handle = e.type
        }
      });
    },
    // 重置请求
    reset() {
      this.page = 1;
      this.getList();
    },
    //切换每页条数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.reset();
    },
    //切换页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList();
    },
    getList(){
      let obj = this.form
      matchingList({
        count:this.limit,
        page:this.page
      }).then((res) => {
        console.log(res.data);
        this.tableData = res.data.data;
        this.total = res.data.total
      });
    },
   
  },
  mounted() {
    this.getList()
  },
};
</script>

<style scoped lang='scss'>
 /deep/.el-dropdown {
          width: 104px;
          font-size: 14px;
          font-weight: 400;
          color: #697b8a;
          text-align: center;
          line-height: 30px;
        }
.a {
    border-radius: 21px;
    border: 1px solid #dde3e8;
    cursor: pointer;
}
.kz {
    border: 1px solid #ceeae0;
    color: #079562;
}
.contract {
  /deep/ .el-table {
    .signature {
      height: 60px;
      border-radius: 2px;
      border: 1px solid #f3f3f3;
    }
  }
  .fy {
    text-align: center;
    padding: 40px 0px;
  }
  .form {
    margin-bottom: 33px;
    /deep/ .el-button,
    .el-input,
    .el-autocomplete {
      width: 100%;
    }
    /deep/ .el-autocomplete-suggestion__wrap {
      li {
        line-height: normal;
        padding: 7px;
        .zw {
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .yw {
          font-size: 12px;
          color: #b4b4b4;
        }

        .highlighted .zw {
          color: #ddd;
        }
      }
    }
  }
}
</style>